module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Opentrader","short_name":"Opentrader","start_url":"/","background_color":"#1c524b","theme_color":"#1c524b","display":"standalone","icon":"src/images/favicon-96x96.png","icons":[{"src":"src/images/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon-96x96.png","sizes":"96x96","type":"image/png"}],"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"3512d19374015f2518d1abd21681ea86"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
